import styled from 'styled-components';

export const AnimalComponent = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  position: relative;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 200;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left ${props => props.offSet}px;

  &:hover {
    cursor: pointer;
  }
`;

export const PigComponent = styled(AnimalComponent)`
`;