import { ActionTypes } from '../util/constants';
import house1 from '../assets/house1.png';
import chest from '../assets/chest.png';
import house2 from '../assets/house2.png';
import bricks1 from '../assets/bricks1.png';
import bricks2 from '../assets/bricks2.png';
import bricks3 from '../assets/bricks3.png';
import bricks6 from '../assets/bricks6.png';
import bricks7 from '../assets/bricks7.png';
import abandoned from '../assets/abandoned.png';
import abandoned2 from '../assets/abandoned2.png';
import house3 from '../assets/house3.png';
import house4 from '../assets/house4.png';
import tower from '../assets/tower.png';
import well from '../assets/well.png';
import water from '../assets/water.gif';
import dirt from '../assets/dirt.png';
import bridge from '../assets/bridge.png';
import npc from '../assets/npc.gif';
import tree3 from '../assets/tree3.png';
import tree4 from '../assets/tree4.png';
import tree5 from '../assets/tree5.png';
import tree6 from '../assets/tree6.png';
import tree7 from '../assets/tree7.png';
import tree8 from '../assets/tree8.png';
import tree9 from '../assets/tree9.png';
import tree10 from '../assets/tree10.png';
import tree11 from '../assets/tree11.png';
import tree12 from '../assets/tree12.png';
import tree13 from '../assets/tree13.png';
import barn from '../assets/barn.png';
import stone2 from '../assets/stone2.png';
import wheat from '../assets/wheat.gif';
import wheatIcon from '../assets/wheatIcon.png';

const mapLayout = {
  '1100200': { name: 'wheat', image: wheat },
  '700200': { name: 'house1', image: house1 },
  '600200': { name: 'house2', image: house2 },
  '500200': { name: 'house3', image: house3 },
  '10001500': { name: 'bricks6', image: bricks6 },
  '12001700': { name: 'bricks7', image: bricks7 },
  '1800900': { name: 'tree10', image: tree10 },
  '10001200': { name: 'tree13', image: tree13 },
  '15001500': { name: 'abandoned', image: abandoned },
  '12001500': { name: 'abandoned2', image: abandoned2 },
  '11001600': { name: 'bricks1', image: bricks1 },
  '13001500': { name: 'bricks2', image: bricks2 },
  '15001400': { name: 'bricks3', image: bricks3 },
  '15001700': { name: 'chest', image: chest },
  '400100': { name: 'Tower', image: tower },
  '800100': { name: 'Tower', image: tower },
  '8001000': { name: 'Tower', image: tower },
  '300200': { name: 'Well', image: well },
  '0300': { name: 'water', image: water },
  '100300': { name: 'bridge', image: bridge },
  '200300': { name: 'water', image: water },
  '300300': { name: 'water', image: water },
  '400300': { name: 'water', image: water },
  '500300': { name: 'water', image: water },
  '600300': { name: 'water', image: water },
  '700300': { name: 'water', image: water },
  '800300': { name: 'water', image: water },
  '900300': { name: 'water', image: water },
  '1000300': { name: 'water', image: water },
  '1100300': { name: 'water', image: water },
  '1200300': { name: 'water', image: water },
  '1300300': { name: 'water', image: water },
  '1400300': { name: 'water', image: water },
  '1500300': { name: 'water', image: water },
  '1600300': { name: 'water', image: water },
  '1700300': { name: 'water', image: water },
  '1800300': { name: 'water', image: water },
  '1900300': { name: 'water', image: water },
  '200400': { name: 'house4', image: house4 },
  '300400': { name: 'dirt', image: dirt },
  '400400': { name: 'dirt', image: dirt },
  '500400': { name: 'dirt', image: dirt },
  '600400': { name: 'dirt', image: dirt },
  '700400': { name: 'dirt', image: dirt },
  '800400': { name: 'barn', image: barn },
  '300500': { name: 'npc', image: npc },
  '0500': { name: 'tree5', image: tree5 },
  '0700': { name: 'tree6', image: tree6 },
  '100800': { name: 'tree7', image: tree7 },
  '1001500': { name: 'tree8', image: tree8 },
  '11001400': { name: 'tree8', image: tree8 },
  '01500': { name: 'tree9', image: tree9 },
  '01600': { name: 'tree10', image: tree10 },
  '16001800': { name: 'tree10', image: tree10 },
  '1600500': { name: 'tree10', image: tree10 },
  '1800400': { name: 'tree11', image: tree11 },
  '1800200': { name: 'tree12', image: tree12 },
  '1600200': { name: 'tree8', image: tree8 },
  '1300100': { name: 'tree7', image: tree7 },
  '900500': { name: 'tree4', image: tree4 },
  '800500': { name: 'stone2', image: stone2 },
  '7000': { name: 'tree3', image: tree3 },
};

const house1Obj = {
  name: 'Small House',
  iconImage: house1,
  image: house1,
  price: 2000,
  description: 'Small house.',
}

const house2Obj = {
  name: 'Log House',
  iconImage: house2,
  image: house2,
  price: 5000,
  description: 'Medium sized house',
}

const house3Obj = {
  name: 'Tile House',
  iconImage: house3,
  image: house3,
  price: 5000,
  description: 'Medium sized house.'
}

const towerObj = {
  name: 'Tower',
  iconImage: tower,
  image: tower,
  price: 10000,
  description: 'Increase defense.'
}

const wellObj = {
  name: 'Well',
  iconImage: well,
  image: well,
  price: 1000,
  description: 'Increase water supply.'
}

const wheatObj = {
  name: 'wheat',
  iconImage: wheatIcon,
  image: wheat,
  price: 1000,
  description: 'Create wheat field'
}

const INITIAL_STATE = {
  builtBuildings: mapLayout,
  buildingList: [house1Obj, house2Obj, house3Obj, towerObj, wellObj, wheatObj],
  selectedBuildingListItem: {},
  selectedTile: null,
  collisionBoxes: [
    {
      x: 830,
      y: 550,
      width: 60,
      height: 30,
    },
    {
      x: 0,
      y: 300,
      width: 100,
      height: 100,
    },
    {
      x: 180,
      y: 300,
      width: 1850,
      height: 100,
    },
    {
      x: 330,
      y: 280,
      width: 15,
      height: 15,
    },
    {
      x: 450,
      y: 280,
      width: 23,
      height: 20,
    },
    {
      x: 850,
      y: 1170,
      width: 23,
      height: 20,
    },
    {
      x: 850,
      y: 280,
      width: 23,
      height: 20,
    },
    {
      x: 525,
      y: 280,
      width: 27,
      height: 20,
    },
    {
      x: 625,
      y: 280,
      width: 27,
      height: 20,
    },
    {
      x: 725,
      y: 280,
      width: 20,
      height: 20,
    },
    {
      x: 825,
      y: 480,
      width: 60,
      height: 20,
    },
    {
      x: 225,
      y: 480,
      width: 60,
      height: 20,
    }
  ]
}

function buildingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SET_COLLISION_BOXES: {
      return {
        ...state,
        collisionBoxes: action.collisionBoxes,
      };
    }
    case ActionTypes.SET_BUILT_BUILDINGS: {
      return {
        ...state,
        builtBuildings: action.builtBuildings,
      };
    }
    case ActionTypes.SET_SELECTED_BUILDING_LIST_ITEM: {
      return {
        ...state,
        selectedBuildingListItem: action.buildingListItem,
      }
    }
    case ActionTypes.SET_SELECTED_TILE: {
      return {
        ...state,
        selectedTile: action.selectedTile,
      }
    }
    default:
      return state;
  }
}

export default buildingsReducer;