import styled from 'styled-components';

export const GeneralSquare = styled.div`
  background-color: ${props => props.selected ? '#ADD8E6' : 'green'};
  height: 100px;
  width: 100px;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
`;

export const HouseSquare = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 202;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const WellSquare = styled(HouseSquare)`
  height: 80px;
  top: ${props => props.coordinates ? props.coordinates.top + 10 : '0'}px;
`;

export const ChestSquare = styled(HouseSquare)`
  height: 35px;
  width: 50px;

  &:hover {
    cursor: pointer;
  }
`;

export const WaterSquare = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 200;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const BridgeSquare = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 200;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TowerSquare = styled(HouseSquare)`
  height: 210px;
  background-size: contain;
  top: ${props => props.coordinates ? props.coordinates.top - 10 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left + 17 : '0'}px;
`;

export const ItemSquare = styled.div`
  height: 60px;
  width: 60px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }
`;

export const TradeItemSquareLeft = styled.div`
  z-index: 300;
  postion: relative;
  display: flex;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
`;

export const TradeItemSquareRight = styled.div`
  z-index: 300;
  postion: relative;
  display: flex;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
`;

export const WheatPoints = styled.div`
  z-index: 300;
  position: absolute;
  color: white;
  top: ${props => props.coordinates ? props.coordinates.top - 40 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left + 5 : '0'}px;
  & p {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  -webkit-animation: moving 2s infinite;
  animation: moving 2s infinite;

  @keyframes moving {
    from {transform: translateY(0px);}
    to {
      transform: translateY(-30px);
      opacity: 0.0;
    }
  }  
`;

export const WheatPlant = styled.div`
  height: 50px;
  width: 50px;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top + 50 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left - 15 : '0'}px;
  z-index: 201;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TradeItemContainer = styled.div`
  height: 100px;
  width: 400px;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 500px;
  left: 300px;
  z-index: 420;
`;

export const Item = styled.div`
  height: 45px;
  width: 45px;
  margin-left: 7px;
  margin-top: 10px;
  position: relative;
  background-image: url(${props => !props.selected ? props.image : ''});
  background-repeat: no-repeat;
  background-color: ${props => props.isItemSelected ? 'yellow' : ''};
  background-size: contain;
  z-index: 100;
`;

export const TradeItem = styled.div`
  height: 45px;
  width: 45px;
  margin-left: 7px;
  margin-top: 10px;
  position: absolute;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 400;
`;

export const ItemRow = styled.div`
  display: flex;
  position: relative;
`;