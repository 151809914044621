import React from 'react';
import get from 'lodash/get';
import { bindActionCreators } from 'redux'
import itemHolder from '../assets/item_holder.png';
import { connect } from 'react-redux'
import { 
  setSelectedBuildingListItem, 
  setBuiltBuildings, 
  setCollisionBoxes, 
  setSelectedTile, 
  setWallet, 
  setNumberOfWheatSquares, 
} from '../actions'
import { ItemSquare, Item, ItemRow } from '../common/squareComponents';
import { InventoryButton, InventoryButtonContainer } from '../common/buttonComponents';
import { BuildTable } from '../common/gameBoardComponents'
import { getCollisionBox } from '../util/util';

const Build = (props) => {
  const handleItemClick = (buildingListItem) => {
    const { selectedBuildingListItem } = props;

    if (!selectedBuildingListItem) {
      props.setSelectedBuildingListItem(buildingListItem);
    } else if (buildingListItem.name === selectedBuildingListItem.name) {
      props.setSelectedBuildingListItem(null);
    } else {
      props.setSelectedBuildingListItem(buildingListItem);
    }
  }

  const renderItemRow = ({ left, middle, right }) => {
    const { selectedBuildingListItem } = props;
    return (
      <ItemRow>
        <ItemSquare 
          onClick={() => {
            if (left) {
              handleItemClick(left)
            }
          }}
          image={itemHolder}
        >
          {left && <Item image={left.iconImage} isItemSelected={left.name === get(selectedBuildingListItem, 'name')} />} 
        </ItemSquare>
        <ItemSquare 
          onClick={() => {
            if (middle) {
              handleItemClick(middle)
            }
          }}
          image={itemHolder}
        >
          {middle && <Item image={middle.iconImage} isItemSelected={middle.name === get(selectedBuildingListItem, 'name')}/>}
        </ItemSquare>
        <ItemSquare 
          onClick={() => {
            if (right) {
              handleItemClick(right)
            }
          }}
          image={itemHolder}
        >
          {right && <Item image={right.iconImage} isItemSelected={right.name === get(selectedBuildingListItem, 'name')}/>}
        </ItemSquare>
      </ItemRow>
    );
  }

  const loadItems = () => {
    const { buildingList } = props;
    let result = [];
    let tempConfig = {};

    for (let i = 0; i < 15; i++) {
      if ((i + 1) % 3 === 0) {
        if (buildingList && ((i + 1) <= buildingList.length)) {
          tempConfig.right = buildingList[i];
        }
        result.push(renderItemRow(tempConfig));
        tempConfig = {};
      } else if (buildingList && ((i + 1) <= buildingList.length)) {
        if ([0,3,6,9,12].includes(i)) {
          tempConfig.left = buildingList[i];
        } else {
          tempConfig.middle = buildingList[i];
        } 
      }
    }

    return result;
  }

  const build = () => {
    const { selectedTile, selectedBuildingListItem, builtBuildings } = props;
    if (selectedTile && selectedBuildingListItem && props.wallet > selectedBuildingListItem.price) {
      const coordinateKey = selectedTile.left.toString() + selectedTile.top.toString();
      const updatedBuiltBuildings = builtBuildings;

      updatedBuiltBuildings[coordinateKey] = {
        name: selectedBuildingListItem.name,
        image: selectedBuildingListItem.image,
      }

      props.setBuiltBuildings(updatedBuiltBuildings);

      // Update Collision Boxes
      const newCollisionBox = getCollisionBox(selectedBuildingListItem.name, selectedTile.left, selectedTile.top)

      if (newCollisionBox) {
        const updatedCollisionBoxes = [...props.collisionBoxes, newCollisionBox];
        props.setCollisionBoxes(updatedCollisionBoxes);
      }

      if (selectedBuildingListItem.name === 'wheat') {
        props.setNumberOfWheatSquares(props.wheatSquares + 1);
      }
      
      props.setSelectedTile(null);
      props.setSelectedBuildingListItem(null);
      props.setWallet(props.wallet - selectedBuildingListItem.price);
    } else if (!selectedTile) {
      alert("You must select a piece of land in order to build first!")
    } else if (!selectedBuildingListItem) {
      alert("You must select a building item in order to build!")
    } else {
      alert("You don't have enough funds to build that item!")
    }
  }

  return (
    <>
      <BuildTable>
        {loadItems()}
        <>
          {props.selectedBuildingListItem &&
          <>
            <p>&nbsp;&nbsp;&nbsp;<strong>Item:</strong> {props.selectedBuildingListItem.name}</p>
            <p>&nbsp;&nbsp;&nbsp;<strong>Price:</strong> ${props.selectedBuildingListItem.price}.00</p>
            <p>&nbsp;&nbsp;&nbsp;<strong>Description:</strong> {props.selectedBuildingListItem.description}</p>
          </>
          }
        </>
        <InventoryButtonContainer>
          <InventoryButton
            onClick={() => {
              build()
            }}
          >
            Build
          </InventoryButton>
        </InventoryButtonContainer>
      </BuildTable>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedBuildingListItem,
      setBuiltBuildings,
      setCollisionBoxes,
      setSelectedTile,
      setWallet,
      setNumberOfWheatSquares,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    selectedBuildingListItem: state.buildings.selectedBuildingListItem,
    buildingList: state.buildings.buildingList,
    selectedTile: state.buildings.selectedTile,
    builtBuildings: state.buildings.builtBuildings,
    collisionBoxes: state.buildings.collisionBoxes,
    wallet: state.status.wallet,
    wheatSquares: state.status.wheatSquares,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Build)