import React, { useEffect, useState } from 'react';
import { PlayerWrapper } from '../common/statusComponents'
import { FredNameLabel } from '../common/gameBoardComponents'
import fredFaceLeft from '../assets/fred_facing_left.png';
import fredFaceRight from '../assets/fred_facing_right.png';
import fredWalkingLeft2 from '../assets/fred_walking_left2.png';
import fredWalkingRight2 from '../assets/fred_walking_right2.png';

const Fred = (props) => {
  const [left, setLeft] = useState(props.coordinates.left);
  const [image, setImage] = useState(props.image);
  const [direction, setDirection] = useState('right');

  useEffect(() => {
    const setMovementAgain = () => {
      if (direction === 'right') {
        if (left > 700) {
          setDirection('standRight')
          setImage(fredFaceRight)
          setTimeout(() => {
            setDirection('left')
          }, 3000)
        } else {
          if (image === fredFaceRight) {
            setImage(fredWalkingRight2)
          } else {
            setImage(fredFaceRight)
          }
          setLeft(left + 6)
        }
      }

      if (direction === 'left') {
        if (left < 300) {
          setDirection('down')
          setImage(fredFaceLeft)
          setTimeout(() => {
            setDirection('right')
          }, 3000)
        } else {
          if (image === fredFaceLeft) {
            setImage(fredWalkingLeft2)
          } else {
            setImage(fredFaceLeft)
          }
          setLeft(left - 6)
        }
      }
    };

    const intervalId = setInterval(() => {
      setMovementAgain()
    }, 100);

    return () => clearInterval(intervalId);
  });

  return (
    <>
        <FredNameLabel
          coordinates={{ top: props.coordinates.top + 110, left: left - 10 }}
        >
        <p>Fred</p>
      </FredNameLabel>
      <PlayerWrapper
        image={image}
        coordinates={{ top: props.coordinates.top, left }}
      />
    </>
  )
}

export default Fred