import React, { useEffect, useState } from 'react';
import { PigComponent } from '../../common/animalComponents'
import PigLeftGif from '../../assets/pigLeftGif.gif';
import PigGif from '../../assets/pigGif.gif';
import oink1 from '../../assets/oink1.wav';
import oink2 from '../../assets/oink2.wav';
import oink3 from '../../assets/oink3.wav';
import oink4 from '../../assets/oink4.wav';

const Piggy = (props) => {
  const [offSet, setOffSet] = useState(10);
  const [left, setLeft] = useState(props.coordinates.left);
  const [top, setTop] = useState(props.coordinates.top);
  const [image, setImage] = useState(props.image);
  const [direction, setDirection] = useState('down');

  useEffect(() => {
    const setOffSetAgain = () => {

    };

    const setMovementAgain = () => {
      if (direction === 'down') {
        if (top > 400) {
          setDirection('right')
          setOffSet(-150)
        } else {
          setTop(top + 3)
        }
      }

      if (direction === 'right') {
        if (left > 600) {
          setDirection('up')
          setOffSet(-75)
        } else {
          setLeft(left + 3)
        }
      }

      if (direction === 'up') {
        if (top < 300) {
          setDirection('left')
          setImage(PigLeftGif)
          setOffSet(0)
        } else {
          setTop(top - 3)
        }
      }

      if (direction === 'left') {
        if (left < 500) {
          setDirection('down')
          setImage(PigGif)
        } else {
          setLeft(left - 3)
        }
      }
      
    };

    const intervalId = setInterval(() => {
      setOffSetAgain()
      setMovementAgain()
    }, 100);

    return () => clearInterval(intervalId);
  });

  const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const piggyOink = () => {
    const piggySounds = [oink1, oink2, oink3, oink4];
    const randIndex = randomIntFromInterval(0, 4);
    const selectedSound = piggySounds[randIndex];
    new Audio(selectedSound).play();
  }

  return (
    <>
      <PigComponent
        width={props.width}
        height={props.height}
        onClick={() => piggyOink()}
        image={image}
        coordinates={{ top, left }}
        offSet={offSet}
      />
    </>
  )
}

export default Piggy