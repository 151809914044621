import React from 'react';
import { connect } from 'react-redux'
import { StatusWallet, WalletCoin, WheatBag, StatusWheat } from '../common/statusComponents';
import coin from '../assets/coin.png';
import wheatBag from '../assets/wheatBag.png';

const status = (props) => {
  
  return (
    <>
      <WalletCoin
        image={coin}
      />
      <StatusWallet>
        <h1>
          {`${props.wallet}`}
        </h1>
      </StatusWallet>
      <WheatBag
       image={wheatBag}
      />
      <StatusWheat>
        <h1>
          {`${props.wheat}`}
        </h1>
      </StatusWheat>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    wallet: state.status.wallet,
    wheat: state.status.wheat,
  }
}

export default connect(mapStateToProps, null)(status);