import { combineReducers } from 'redux';
import items from './items';
import status from './status';
import buildings from './buildings';
import reduceReducers from 'reduce-reducers';

export default reduceReducers(
  combineReducers({
    items,
    status,
    buildings,
  })
);