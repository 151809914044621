import React, { useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import GameSquare from './GameSquare';
import { setSelectedTile } from '../actions';
import { GameBoardWrapper } from '../common/gameBoardComponents.js';
import Piggy from './animals/Piggy';
import { CollapseSideWidgetButton, InventoryTab, BuildTab } from '../common/buttonComponents';
import grass from '../assets/grass.png';
import pig from '../assets/pigGif.gif';
import Inventory from './Inventory';
import Build from './Build';
import Player from './Player';
import Fred from './Fred';

const GameBoard = (props) => {
  const [rows] = useState(20);
  const [columns] = useState(20);
  const [isSideWidgetExpanded, setWidgetExpanded] = useState(true); 
  const [showInventory, setInventory] = useState(true); 
  const [showBuild, setBuild] = useState(false); 

  const renderGameSquares = () => {
    const result = [];

    for (let i = 0; i < columns; i++) {
      let top = 0;
      let left = i * 100;
      for (let j = 0; j < rows; j++) {
        top = j * 100;

        result.push(<GameSquare 
          coordinates={{ top, left }}
          imageBackground={grass}
          />);
      }
    }

    return result;
  }
  
  return (
    <>
      <GameBoardWrapper>
        {renderGameSquares()}
      </GameBoardWrapper>
      <CollapseSideWidgetButton
        onClick={() => setWidgetExpanded(!isSideWidgetExpanded)}>
        {isSideWidgetExpanded ? 'Collapse' : 'Expand'}
      </CollapseSideWidgetButton>
      {isSideWidgetExpanded &&
        <>
          {showInventory && <Inventory />}
          {showBuild && <Build />}
          <InventoryTab
            onClick={() => {
              setInventory(true);
              setBuild(false);
            }}>
            Inventory
          </InventoryTab>
          <BuildTab
            onClick={() => {
              setInventory(false);
              setBuild(true);
            }}>
            Build
          </BuildTab>
        </>
      } 
      <Player />
      <Piggy 
        image={pig}
        height={70}
        width={70}
        coordinates={{ top: 330, left: 400 }}
      />
      <Fred 
        coordinates={{ top: 30, left: 400 }}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedTile,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    selectedTile: state.buildings.selectedTile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameBoard);