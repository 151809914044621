const getCollisionBox = (building, x, y) => {
  switch (building) {
    case 'Tower':
      return {
        x: x + 50,
        y: y + 180,
        width: 23,
        height: 20,
      };
    case 'Well':
      return {
        x: x + 30,
        y: y + 70,
        width: 15,
        height: 15,
      };
    case 'Log House':
    case 'Tile House':
      return {
        x: x + 25,
        y: y + 80,
        width: 27,
        height: 20,
      };
    case 'Small House':
      return {
        x: x + 25,
        y: y + 80,
        width: 20,
        height: 20,
      };
    case 'stone2':
      return {
        x: x + 30,
        y: y + 50,
        width: 60,
        height: 30,
      };
    default:
      return null
  }
}

export {
  getCollisionBox
}