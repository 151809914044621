const SET_SELECTED_INVENTORY_ITEM = 'SET_SELECTED_INVENTORY_ITEM';
const SET_CURRENT_INVENTORY_ITEMS = 'SET_CURRENT_INVENTORY_ITEMS';
const SET_IS_INVENTORY_ITEM_SELECTED = 'SET_IS_INVENTORY_ITEM_SELECTED';
const SET_WALLET = 'SET_WALLET';
const SET_EXPERIENCE = 'SET_EXPERIENCE';
const SET_LEVEL = 'SET_LEVEL';
const SET_SELECTED_NPC = 'SET_SELECTED_NPC';
const SET_TRADE_MODE = 'SET_TRADE_MODE';
const SET_PLAYER_POSITION = 'SET_PLAYER_POSITION';
const SET_BUILT_BUILDINGS = 'SET_BUILT_BUILDINGS';
const SET_SELECTED_BUILDING_LIST_ITEM = 'SET_SELECTED_BUILDING_LIST_ITEM';
const SET_COLLISION_BOXES = 'SET_COLLISION_BOXES';
const SET_SELECTED_TILE = 'SET_SELECTED_TILE';
const SET_WHEAT = 'SET_WHEAT';
const SET_WHEAT_SQUARES = 'SET_WHEAT_SQUARES';

const squareTypes = ['building', 'farm', 'npc', 'grass', 'water'];

const ActionTypes = {
  SET_SELECTED_INVENTORY_ITEM,
  SET_CURRENT_INVENTORY_ITEMS,
  SET_IS_INVENTORY_ITEM_SELECTED,
  SET_WALLET,
  SET_EXPERIENCE,
  SET_LEVEL,
  SET_SELECTED_NPC,
  SET_TRADE_MODE,
  SET_PLAYER_POSITION,
  SET_BUILT_BUILDINGS,
  SET_SELECTED_BUILDING_LIST_ITEM,
  SET_COLLISION_BOXES,
  SET_SELECTED_TILE,
  SET_WHEAT,
  SET_WHEAT_SQUARES,
}

export {
  squareTypes,
  ActionTypes,
}