import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSelectedNpc, setTradeMode, setInventoryItems, setSelectedInventoryItem } from '../actions';
import { TradeBox, TradeArrowTop, TradeArrowBottom } from '../common/gameBoardComponents';
import { TradeItemSquareLeft, TradeItemSquareRight, TradeItemContainer, TradeItem } from '../common/squareComponents';
import { TradeButtonContainer, ConfirmButton, CancelButton } from '../common/buttonComponents';
import itemHolder from '../assets/item_holder.png';
import tradeArrowTop from '../assets/trade_arrow.png';
import tradeArrowBottom from '../assets/trade_arrow2.png';
import wheatIcon from '../assets/wheatIcon.png';
import coinsSound from '../assets/coins.wav';

const tradeWindow = (props) => {
  const handleConfirmClick = () => {
    const itemName = props.selectedInventoryItem.name;
    const newCurrentItems = props.currentInventoryItems.reduce((res, item) => {
      if (item.name !== itemName) {
        res.push(item);
      }
      return res;
    }, []);

    newCurrentItems.push({ image: wheatIcon, price: 100, name: 'wheat seeds', description: 'Plant it to get wheat.' });

    props.setSelectedInventoryItem(null)
    props.setInventoryItems(newCurrentItems);
    props.setTradeMode(false)
    new Audio(coinsSound).play();
  }

  return (
    <>
      <TradeItemContainer>
        <TradeItemSquareLeft image={itemHolder}>
          <TradeItem 
            image={props.selectedInventoryItem.image}
          />
        </TradeItemSquareLeft>
        <TradeItemSquareRight image={itemHolder} >
          <TradeItem 
            image={wheatIcon}
          />
        </TradeItemSquareRight>
      </TradeItemContainer>
      <TradeButtonContainer>
        <CancelButton
          onClick={() => {
            props.setTradeMode(false);
          }}>
          Cancel
        </CancelButton>
        <ConfirmButton
          onClick={() => {
            handleConfirmClick();
          }}>
          Confirm
        </ConfirmButton>
      </TradeButtonContainer>
      <TradeBox
        onClick={() => handleConfirmClick()}
        isSelected={true}
        image={props.image} 
        coordinates={props.coordinates}
      >
        <h3>Are you sure you want to trade?</h3>
        <TradeArrowTop image={tradeArrowTop}/>
        <TradeArrowBottom image={tradeArrowBottom} />
      </TradeBox>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedInventoryItem,
      setInventoryItems,
      setTradeMode,
      setSelectedNpc,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    selectedNpc: state.status.selectedNpc,
    selectedInventoryItem: state.items.selectedInventoryItem,
    currentInventoryItems: state.items.currentInventoryItems,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tradeWindow);