import './App.css';
import GameBoard from './components/GameBoard';
import Status from './components/Status';
import { connect } from 'react-redux'
import TradeWindow from './components/TradeWindow';

const App = (props) => {
  return (
    <div className="App">
      {props.tradeMode &&
        <TradeWindow />
      }
      <Status />
      <GameBoard />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tradeMode: state.status.tradeMode,
  }
}

export default connect(mapStateToProps, null)(App);
