import styled from 'styled-components';

export const TradeButtonContainer = styled.div`
  height: 100px;
  width: 400px;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 380px;
  left: 300px;
  z-index: 450;
`;

export const ConfirmButton = styled.button`
  background-color: #55a846;
  color: white;
  width: 100px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

export const CancelButton = styled.button`
  background-color: #db0210;
  color: white;
  width: 100px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

export const InventoryButtonContainer = styled.div`
  height: 100px;
  width: 300px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: -20px;
  left: 0px;
  z-index: 300;
`;

export const InventoryButton = styled.button`
  background-color: #db0210;
  color: white;
  width: 80px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

export const CollapseSideWidgetButton = styled.button`
  width: 100px;
  height: 20px;
  position: fixed;
  right: 190px;
  top: 0px;
  z-index: 450;
  background-color: #231c1a;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

export const InventoryTab = styled.button`
  background-color: #231c1a;
  position: fixed;
  border-radius: 15px;
  top: 660px;
  right: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
  width: 100px;
  color: #FFD700;
  border-top: none !important;
  border-left: none !important;

  &:hover {
    cursor: pointer;
  }
`;

export const BuildTab = styled.button`
  background-color: #231c1a;
  position: fixed;
  border-radius: 15px;
  top: 660px;
  right: 100px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
  width: 100px;
  color: red;
  border-top: none !important;
  border-left: none !important;

  &:hover {
    cursor: pointer;
  }
`;