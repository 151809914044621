import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSelectedNpc, setTradeMode } from '../actions';
import { NpcCharacter, SelectedEllipse, NpcChatBubble, NpcChatBubbleArrow, TypeEffect, NpcNameLabel } from '../common/gameBoardComponents';

const Npc = (props) => {
  useEffect(() => {
    if (!isNpcWithinRange()) {
      props.setSelectedNpc(false);
      props.setTradeMode(false);
    }
  });
  
  const isNpcWithinRange = () => {
    if (props.currentPosition.left <= props.coordinates.left + 100 
      && props.currentPosition.left >= props.coordinates.left - 100 
      && props.currentPosition.top <= props.coordinates.top + 100
      && props.currentPosition.top >= props.coordinates.top - 100) {
        return true;
    }
    return false;
  }

  const handleNpcClick = () => {
    if (isNpcWithinRange()) {
      if (props.selectedNpc === true) {
        props.setTradeMode(false);
      }
      props.setSelectedNpc(!props.selectedNpc);
    }
  }

  return (
    <>
      {props.selectedNpc &&
        <SelectedEllipse 
          coordinates={props.coordinates}
        />
      }
      {isNpcWithinRange() && 
        <>
          <NpcChatBubble
            coordinates={props.coordinates}
            >
            <TypeEffect>
              <p>
                Hello stranger, how can I help you?
              </p>
            </TypeEffect>
          </NpcChatBubble>
          <NpcChatBubbleArrow
            coordinates={props.coordinates}
          />
        </>}
      <NpcNameLabel
        coordinates={props.coordinates}
      >
        <p>Shervin</p>
      </NpcNameLabel>
      <NpcCharacter
        onClick={() => handleNpcClick()}
        isSelected={true}
        image={props.image} 
        coordinates={props.coordinates}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedNpc,
      setTradeMode,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    selectedNpc: state.status.selectedNpc,
    currentPosition: state.status.playerPosition,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Npc);