import React from 'react';
import get from 'lodash/get';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { InventoryTable } from '../common/gameBoardComponents';
import { ItemSquare, Item, ItemRow } from '../common/squareComponents';
import { InventoryButton, InventoryButtonContainer } from '../common/buttonComponents';
import { setSelectedInventoryItem, setInventoryItems, setWallet, setTradeMode } from '../actions';
import itemHolder from '../assets/item_holder.png';
import coinsSound from '../assets/coins.wav';

class Inventory extends React.Component { 
  handleItemClick(item) {
    const { selectedItem } = this.props;

    if (!selectedItem) {
      this.props.setSelectedInventoryItem(item);
    } else if (item.name === selectedItem.name) {
      this.props.setSelectedInventoryItem(null);
      this.props.setTradeMode(false);
    } else {
      this.props.setSelectedInventoryItem(item);
    }
  }

  renderItemRow({ left, middle, right }) {
    const { selectedItem } = this.props;
    return (
      <ItemRow>
        <ItemSquare 
          onClick={() => {
            if (left) {
              this.handleItemClick(left)
            }
          }}
          image={itemHolder}
        >
          {left && <Item image={left.image} isItemSelected={left.name === get(selectedItem, 'name')} />} 
        </ItemSquare>
        <ItemSquare 
          onClick={() => {
            if (middle) {
              this.handleItemClick(middle)
            }
          }}
          image={itemHolder}
        >
          {middle && <Item image={middle.image} isItemSelected={middle.name === get(selectedItem, 'name')}/>}
        </ItemSquare>
        <ItemSquare 
          onClick={() => {
            if (right) {
              this.handleItemClick(right)
            }
          }}
          image={itemHolder}
        >
          {right && <Item image={right.image} isItemSelected={right.name === get(selectedItem, 'name')}/>}
        </ItemSquare>
      </ItemRow>
    );
  }

  loadItems() {
    const { currentInventoryItems } = this.props;
    let result = [];
    let tempConfig = {};

    for (let i = 0; i < 15; i++) {
      if ((i + 1) % 3 === 0) {
        if (currentInventoryItems && ((i + 1) <= currentInventoryItems.length)) {
          tempConfig.right = currentInventoryItems[i];
        }
        result.push(this.renderItemRow(tempConfig));
        tempConfig = {};
      } else if (currentInventoryItems && ((i + 1) <= currentInventoryItems.length)) {
        if ([0,3,6,9,12].includes(i)) {
          tempConfig.left = currentInventoryItems[i];
        } else {
          tempConfig.middle = currentInventoryItems[i];
        } 
      }
    }

    return result;
  }

  removeItem(selectedItem) {
    // todo
  }

  tradeItem(selectedItem) {
    const { selectedNpc } = this.props;
    if (selectedItem && selectedNpc) {
      this.props.setTradeMode(true);
    } else if (!selectedNpc) {
      alert('You must select someone to trade with!');
    } else {
      alert('You must select an item to trade!');
    }
  }

  sellItem(selectedItem) {
    if (selectedItem) {
      const newCurrentItems = this.props.currentInventoryItems.reduce((res, item) => {
        if (item.name !== selectedItem.name) {
          res.push(item);
        }
        return res;
      }, []);

      this.props.setWallet(this.props.wallet + selectedItem.price);
      this.props.setInventoryItems(newCurrentItems);
      this.props.setSelectedInventoryItem(null);
      new Audio(coinsSound).play();
    } else {
      alert('You need to select an item to sell first');
    }
  }

  render() {
    const { selectedItem } = this.props;
    return (
      <>
        <InventoryTable>
          {this.loadItems()}
            <>
             {selectedItem &&
              <>
                <p>&nbsp;&nbsp;&nbsp;<strong>Item:</strong> {selectedItem.name}</p>
                <p>&nbsp;&nbsp;&nbsp;<strong>Price:</strong> ${selectedItem.price}.00</p>
                <p>&nbsp;&nbsp;&nbsp;<strong>Description:</strong> {selectedItem.description}</p>
              </>
              }
            </>
          <InventoryButtonContainer>
            <InventoryButton
              onClick={() => {
                this.tradeItem(selectedItem)
              }}
            >
              Trade
            </InventoryButton>
            <InventoryButton
              onClick={() => {
                this.sellItem(selectedItem);
              }}>
              Sell
            </InventoryButton>
            <InventoryButton>
              Buy
            </InventoryButton>
          </InventoryButtonContainer>
        </InventoryTable>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedInventoryItem,
      setInventoryItems,
      setWallet,
      setTradeMode,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    selectedItem: state.items.selectedInventoryItem,
    currentInventoryItems: state.items.currentInventoryItems,
    wallet: state.status.wallet,
    selectedNpc: state.status.selectedNpc,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);