import { ActionTypes } from '../util/constants';
import playerFacingFront from '../assets/ch_facing_front.png'

const INITIAL_STATE = {
  wallet: 10000000,
  wheat: 0,
  wheatSquares: 1,
  experience: 0,
  level: 0,
  selectedNpc: false,
  tradeMode: false,
  playerPosition: {
    left: 300,
    top: 650,
    image: playerFacingFront,
  }
}

function statusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SET_WALLET: {
      return {
        ...state,
        wallet: action.wallet,
      };
    }
    case ActionTypes.SET_WHEAT: {
      return {
        ...state,
        wheat: action.wheat,
      };
    }
    case ActionTypes.SET_WHEAT_SQUARES: {
      return {
        ...state,
        wheatSquares: action.wheatSquares,
      };
    }
    case ActionTypes.SET_EXPERIENCE: {
      return {
        ...state,
        experience: action.experience,
      }
    }
    case ActionTypes.SET_LEVEL: {
      return {
        ...state,
        level: action.level,
      }
    }
    case ActionTypes.SET_SELECTED_NPC: {
      return {
        ...state,
        selectedNpc: action.selectedNpc,
      }
    }
    case ActionTypes.SET_TRADE_MODE: {
      return {
        ...state,
        tradeMode: action.tradeMode,
      }
    }
    case ActionTypes.SET_PLAYER_POSITION: {
      return {
        ...state,
        playerPosition: action.playerPosition,
      }
    }
    default:
      return state;
  }
}

export default statusReducer;