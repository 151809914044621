import styled from 'styled-components';

export const GameBoardWrapper = styled.div`
  position: absolute;
  left: 0px;
  width: 1000px;
  height: 700px;
`;

export const InventoryTable = styled.div`
  position: fixed;
  color: white;
  text-align: left;
  right: 0px;
  width: 300px;
  height: 670px;
  z-index: 400;
  background-color: #231c1a;
`;

export const BuildTable = styled(InventoryTable)`
`;

export const NpcNameLabel = styled.div`
  z-index: 300;
  position: absolute;
  color: white;
  top: ${props => props.coordinates ? props.coordinates.top - 40 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left - 10 : '0'}px;
  & p {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
`;

export const FredNameLabel = styled.div`
  z-index: 200;
  position: absolute;
  color: white;
  top: ${props => props.coordinates ? props.coordinates.top - 40 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left + 5 : '0'}px;
  & p {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
`;

export const NpcCharacter = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 100;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    cursor: pointer;
  }
`;

export const NpcChatBubble = styled.div`
  height: 50px;
  display:flex;
  position: absolute;
  opacity: 0.8;
  padding: 5px;
  width: 263px;
  top: ${props => props.coordinates ? props.coordinates.top - 65 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left - 100 : '0'}px;
  z-index: 350;
  color: white;
  background-color: black;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TypeEffect = styled.div`
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */

  animation: 
    typing 1.5s steps(40, end),
    blink-caret .75s step-end infinite;

  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
`;

export const NpcChatBubbleArrow = styled.div`
  width: 0;
  height: 0;
  opacity: 0.8;
  border-top: 10px solid black;
  border-left: 10px solid transparent;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top - 5 : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 350;
`;

export const SelectedEllipse = styled.div`
  height: 20px;
  width: 50px;
  position: absolute;
  top: ${props => props.coordinates ? props.coordinates.top + 30: '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%);
  border-radius: 50%;
  z-index: 50;
`;

export const TradeBox = styled.div`
  height: 300px;
  width: 400px;
  position: fixed;
  bottom: 400px;
  left: 300px;
  background: linear-gradient(90deg, rgba(235,137,3,1) 0%, rgba(91,78,78,1) 0%, rgba(0,0,0,1) 100%);
  z-index: 410;

  & h3 {
    color: white;
  }
`;

export const TradeArrowTop = styled.div`
  height: 100px;
  width: 110px;
  bottom: 130px;
  left: 145px;
  position: absolute;
  z-index: 200;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TradeArrowBottom = styled(TradeArrowTop)`
  bottom: 20px;
`;