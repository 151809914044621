import { ActionTypes } from "../util/constants";

export const setSelectedInventoryItem = (item) => {
  return {
    type: ActionTypes.SET_SELECTED_INVENTORY_ITEM,
    item,
  }
}

export const setInventoryItems = (inventoryItems) => {
  return {
    type: ActionTypes.SET_CURRENT_INVENTORY_ITEMS,
    inventoryItems,
  }
}

export const setIsInventoryItemSelected = (isSelected) => {
  return {
    type: ActionTypes.SET_IS_INVENTORY_ITEM_SELECTED,
    isSelected,
  }
}

export const setLevel = (level) => {
  return {
    type: ActionTypes.SET_LEVEL,
    level
  }
}

export const setExperience = (experience) => {
  return {
    type: ActionTypes.SET_EXPERIENCE,
    experience
  }
}

export const setWallet = (wallet) => {
  return {
    type: ActionTypes.SET_WALLET,
    wallet
  }
}

export const setSelectedNpc = (selectedNpc) => {
  return {
    type: ActionTypes.SET_SELECTED_NPC,
    selectedNpc
  }
}

export const setTradeMode = (tradeMode) => {
  return {
    type: ActionTypes.SET_TRADE_MODE,
    tradeMode
  }
}

export const setPlayerPosition = (playerPosition) => {
  return {
    type: ActionTypes.SET_PLAYER_POSITION,
    playerPosition
  }
}

export const setBuiltBuildings = (builtBuildings) => {
  return {
    type: ActionTypes.SET_BUILT_BUILDINGS,
    builtBuildings
  }
}

export const setSelectedBuildingListItem = (buildingListItem) => {
  return {
    type: ActionTypes.SET_SELECTED_BUILDING_LIST_ITEM,
    buildingListItem
  }
}

export const setCollisionBoxes = (collisionBoxes) => {
  return {
    type: ActionTypes.SET_COLLISION_BOXES,
    collisionBoxes
  }
}

export const setSelectedTile = (selectedTile) => {
  return {
    type: ActionTypes.SET_SELECTED_TILE,
    selectedTile
  }
}

export const setWheat = (wheat) => {
  return {
    type: ActionTypes.SET_WHEAT,
    wheat
  }
}

export const setNumberOfWheatSquares = (wheatSquares) => {
  return {
    type: ActionTypes.SET_WHEAT_SQUARES,
    wheatSquares
  }
}