import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PlayerWrapper } from '../common/statusComponents'
import { setPlayerPosition } from '../actions'
import playerBackWalking1 from '../assets/ch_walking_back_1.png'
import playerBackWalking2 from '../assets/ch_walking_back_2.png'
import playerFrontWalking1 from '../assets/ch_walking_front_1.png'
import playerFrontWalking2 from '../assets/ch_walking_front_2.png'
import playerLeftWalking1 from '../assets/ch_walking_left_1.png'
import playerLeftWalking2 from '../assets/ch_walking_left_2.png'
import playerFacingLeft from '../assets/ch_facing_left.png'
import playerRightWalking1 from '../assets/ch_walking_right_1.png'
import playerRightWalking2 from '../assets/ch_walking_right_2.png'
import playerFacingRight from '../assets/ch_facing_right.png'

const Player = (props) => {
  useEffect(() => {
    document.addEventListener('keydown', movePlayer);
    return function cleanup() {
      document.removeEventListener('keydown', movePlayer);
    };
  });

  // left + bottom

  const checkIsBlocked = (direction, nextPosition) => {
    const { x, y, } = nextPosition;

    const playerBox = {
      x,
      y,
      width: 45,
      height: 45,
    }

    for (let i = 0; i < props.collisionBoxes.length; i++) {
      const collisonBox = props.collisionBoxes[i];
      if (collisonBox.x < playerBox.x + playerBox.width &&
        collisonBox.x + collisonBox.width > playerBox.x &&
        collisonBox.y < playerBox.y + playerBox.height &&
        collisonBox.y + collisonBox.height > playerBox.y) {
        return true;
      }
    }

    // game bounds
    if (direction === 'left') {
      if (props.currentPosition.left < 10) {
        return true;
      }
    }

    if (direction === 'right') {
      if (props.currentPosition.left > 1950) {
        return true;
      }
    }

    if (direction === 'down') {
      if (props.currentPosition.top > 1950) {
        return true;
      }
    }

    if (direction === 'up') {
      if (props.currentPosition.top < 5) {
        return true;
      }    
    }

    return false;
  }

  const getImage = (key, image) => {
    if (key === 'w') {
      if (image === playerBackWalking1) {
        return playerBackWalking2;
      } 
      return playerBackWalking1;
    }

    if (key === 's') {
      if (image === playerFrontWalking1) {
        return playerFrontWalking2;
      } 
      return playerFrontWalking1;
    }

    if (key === 'a') {
      if (image === playerLeftWalking1 || image === playerLeftWalking2) {
        return playerFacingLeft;
      } 

      if (image === playerFacingLeft) {
        return playerLeftWalking2;
      }

      return playerLeftWalking1;
    }

    if (key === 'd') {
      if (image === playerRightWalking1 || image === playerRightWalking2) {
        return playerFacingRight;
      } 

      if (image === playerFacingRight) {
        return playerRightWalking2;
      }

      return playerRightWalking1;
    }
  }

  const movePlayer = (event) => {
    const { currentPosition } = props;

    let image;
    
    switch (event.key) {
      case 'w':
        image = getImage('w', currentPosition.image);
        const isUpBlocked = checkIsBlocked('up', { x: currentPosition.left, y: currentPosition.top - 7 });
        props.setPlayerPosition({
          left: currentPosition.left,
          top: isUpBlocked ? currentPosition.top : currentPosition.top - 7,
          image,
        });
        break;
      case 's':
        image = getImage('s', currentPosition.image);
        const isDownBlocked = checkIsBlocked('down', { x: currentPosition.left, y: currentPosition.top + 7 });
        props.setPlayerPosition({
          left: currentPosition.left,
          top: isDownBlocked ? currentPosition.top : currentPosition.top + 7,
          image,
        });
        break;
      case 'a':
        image = getImage('a', currentPosition.image);
        const isLeftBlocked = checkIsBlocked('left', { x: currentPosition.left - 10, y: currentPosition.top });
        props.setPlayerPosition({
          left: isLeftBlocked ? currentPosition.left : currentPosition.left - 10,
          top: currentPosition.top,
          image,
        });
        break;
      case 'd':
        image = getImage('d', currentPosition.image);
        const isRightBlocked = checkIsBlocked('right', { x: currentPosition.left + 10, y: currentPosition.top });
        props.setPlayerPosition({
          left: isRightBlocked ? currentPosition.left : currentPosition.left + 10,
          top: currentPosition.top,
          image,
        });
        break;
      default:
        image = playerFrontWalking1;
        props.setPlayerPosition({
          left: currentPosition.left,
          top: currentPosition.top,
          image,
        });
    }

    document.removeEventListener('keydown', movePlayer);
  }

  return (
    <>
      <PlayerWrapper
        image={props.currentPosition.image}
        coordinates={
          {
            left: props.currentPosition.left,
            top: props.currentPosition.top,
          }
        }
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPlayerPosition,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    currentPosition: state.status.playerPosition,
    builtBuildings: state.buildings.builtBuildings,
    collisionBoxes: state.buildings.collisionBoxes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);


