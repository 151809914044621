import styled from 'styled-components';

export const StatusWallet = styled.div`
  z-index: 300;
  position fixed;
  color: white;
  margin-left: 50px;
`;

export const WalletCoin = styled.div`
  z-index: 300;
  margin-top: 20px;
  position: fixed;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
`;

export const WheatBag = styled.div`
  z-index: 300;
  margin-top: 60px;
  position: fixed;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
`;

export const StatusWheat = styled.div`
  z-index: 300;
  position fixed;
  color: white;
  margin-left: 50px;
  margin-top: 50px;
`;

export const PlayerWrapper = styled.div`
  height: 45px;
  width: 45px;
  position: relative;
  top: ${props => props.coordinates ? props.coordinates.top : '0'}px;
  left: ${props => props.coordinates ? props.coordinates.left : '0'}px;
  z-index: 200;
  background-image: url(${props => props.image ? props.image : ''});
  background-repeat: no-repeat;
  background-size: contain;
`;