import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get';
import { setBuiltBuildings, setSelectedBuildingListItem, setSelectedTile } from '../actions'
import { GeneralSquare, HouseSquare, TowerSquare, BridgeSquare, WaterSquare, WellSquare, ChestSquare } from '../common/squareComponents';
import WheatSquare from './WheatSquare'
import Npc from './Npc';
import water from '../assets/water.gif';

const GameSquare = (props) => {
  const componentType = props.builtBuildings[props.coordinates.left.toString() + props.coordinates.top.toString()];

  const getComponent = () => {
    if (componentType && componentType.name === 'Well') {
      return (
      <WellSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType && componentType.name === 'chest') {
      return (
      <ChestSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType && componentType.name === 'Tower') {
      return (
      <TowerSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType && componentType.name === 'water') {
      return (
      <WaterSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType && componentType.name === 'wheat') {
      return (
      <WheatSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType && componentType.name === 'bridge') {
      return (
        <>
          <WaterSquare
            image={water} 
            coordinates={props.coordinates}
          />
          <BridgeSquare
            image={componentType.image} 
            coordinates={props.coordinates}
          />
        </>);
    }

    if (componentType && componentType.name === 'dirt') {
      return (
      <WaterSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType && componentType.name === 'npc') {
      return (
      <Npc
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }

    if (componentType) {
      return (
      <HouseSquare
        image={componentType.image} 
        coordinates={props.coordinates}
      />);
    }
  }

  return (
    <>
      {getComponent()}
      <GeneralSquare
        onClick={() => {
          if (props.coordinates.top === get(props, 'selectedTile.top') && props.coordinates.left === get(props, 'selectedTile.left')) {
            props.setSelectedTile(null)
          } else {
            props.setSelectedTile({ top: props.coordinates.top, left: props.coordinates.left })
          }
        }}
        selected={props.coordinates.top === get(props, 'selectedTile.top') && props.coordinates.left === get(props, 'selectedTile.left')}
        coordinates={props.coordinates}
        image={props.imageBackground} 
      >
      </GeneralSquare>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setBuiltBuildings,
      setSelectedBuildingListItem,
      setSelectedTile,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    builtBuildings: state.buildings.builtBuildings,
    selectedTile: state.buildings.selectedTile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameSquare);;