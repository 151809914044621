import { ActionTypes } from '../util/constants';
import goldDiamond from '../assets/gold_diamond.png';
import blueDiamond from '../assets/blue_diamond.png';
import redDiamond from '../assets/red_diamond.png';
import plum from '../assets/plum_farm.png';
import goldBar from '../assets/goldBar.png';
import silverBar from '../assets/silverBar.png';
import potion1 from '../assets/potion1.png';
import potion2 from '../assets/potion2.png';
import apples from '../assets/apples.png';
import bread1 from '../assets/bread1.png';
import bread2 from '../assets/bread2.png';

const bread1Obj = {
  name: 'Wheat Bread',
  image: bread1,
  price: 50,
  description: 'Great in the toaster.',
}

const bread2Obj = {
  name: 'Sourdough Bread',
  image: bread2,
  price: 50,
  description: 'A full loaf.',
}

const potion1Obj = {
  name: 'Grow Potion',
  image: potion1,
  price: 100,
  description: 'Decrease grow time.',
}

const potion2Obj = {
  name: 'Energy Potion',
  image: potion2,
  price: 100,
  description: 'Does the job.',
}

const goldBarObj = {
  name: 'Gold Bar',
  image: goldBar,
  price: 2000,
  description: 'Very rare metal.',
}

const silverBarObj = {
  name: 'Silver Bar',
  image: silverBar,
  price: 1000,
  description: 'A precious metal.',
}

const applesObj = {
  name: 'Apples',
  image: apples,
  price: 100,
  description: 'Absolutely delicious.',
}

const blueDiamondObj = {
  name: 'Blue Diamond',
  image: blueDiamond,
  price: 300,
  description: 'Blue like the sea.',
}

const redDiamondObj = {
  name: 'Red Diamond',
  image: redDiamond,
  price: 400,
  description: 'Such a lovely gem.'
}

const goldDiamondObj = {
  name: 'Gold Diamond',
  image: goldDiamond,
  price: 500,
  description: 'Astounding.'
}

const plumObj = {
  name: 'Plum',
  image: plum,
  price: 50,
  description: 'Smells sweet.'
}

const INITIAL_STATE = {
  selectedInventoryItem: null,
  currentInventoryItems: [blueDiamondObj, redDiamondObj, goldDiamondObj, plumObj, applesObj, potion1Obj, potion2Obj, silverBarObj, goldBarObj, bread1Obj, bread2Obj],
}

function itemsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_INVENTORY_ITEM: {
      return {
        ...state,
        selectedInventoryItem: action.item,
      };
    }
    case ActionTypes.SET_CURRENT_INVENTORY_ITEMS: {
      return {
        ...state,
        currentInventoryItems: action.inventoryItems,
      }
    }
    default:
      return state;
  }
}

export default itemsReducer;

