import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setWheat } from '../actions';
import { WheatPlant, WheatPoints } from '../common/squareComponents';

const WheatSquare = (props) => {
  const { image, coordinates } = props;
  const [showPoints, setPoints] = useState(false);

  useEffect(() => {
    const setWheatAgain = () => {
      const { wheat, setWheat, wheatSquares } = props;
      const newWheat = wheat + (100*wheatSquares);
      setWheat(newWheat)
    };

    const intervalId = setInterval(() => {
      setWheatAgain()
    }, 2000);

    setTimeout(() => {
      setPoints(true);
    }, 2000)

    return () => clearInterval(intervalId);
  });

  const renderWheatPlants = () => {
    const results = []

    let top = 0;
    let left = 0;

    for (let i = 0; i < 10; i++) {
      for (let j = 0; j < 10; j++) {
        results.push(
          <WheatPlant
            image={image}
            coordinates={{ top: coordinates.top - top, left: coordinates.left + left }}
          />
        )
        left = left + 10;
      }

      top = top + 10;
      left = 0;
    }

    return results;
  }

  return (
    <>
      {showPoints &&
        <WheatPoints
          coordinates={coordinates}
        >
          <p>+100</p>
        </WheatPoints>
      }
      {renderWheatPlants()}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setWheat,
    },
    dispatch
  )
}

const mapStateToProps = (state) => {
  return {
    wheat: state.status.wheat,
    wheatSquares: state.status.wheatSquares,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WheatSquare);